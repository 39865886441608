





// Core
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'ProjectsContainer',
})
export default class ProjectsContainerComponent extends Vue {}
